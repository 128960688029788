// If you don't want to use TypeScript you can delete this file!
import { PageProps, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Wrapper } from '@components/Wrapper';

interface DataProps {}

const UsingTypescript: React.FC<PageProps<DataProps>> = () => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);
	const source = files.allFile.edges.find(({ node }) => node.name === 'officeimage').node.childImageSharp.gatsbyImageData;
	return (
		<Wrapper>
			<h1>Gatsby supports TypeScript by default!</h1>
			<p>
				This means that you can create and write <em>.ts/.tsx</em> files for your
				pages, components etc. Please note that the <em>gatsby-*.js</em> files
				(like gatsby-node.js) currently don't support TypeScript yet.
			</p>
			<p>
				For type checking you'll want to install <em>typescript</em> via npm and
				run <em>tsc --init</em> to create a <em>.tsconfig</em> file.
			</p>
			<p>
				You're currently on the page "" which was built on{' '}
			</p>
			<p>
				To learn more, head over to our{' '}
				<a href="https://www.gatsbyjs.com/docs/typescript/">
					documentation about TypeScript
				</a>
				.
			</p>
			<GatsbyImage image={source} alt={source.name} />
		</Wrapper>
	);
};

export default UsingTypescript;
