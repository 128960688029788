import * as React from 'react';

import * as styles from './styles.module.scss';

interface WrapperProps {
	id?: string;
}

export const Wrapper: React.FunctionComponent<WrapperProps> = ({ children, id }) => (
	<div id={id} className={styles.wrapper}>
		{children}
	</div>
);
